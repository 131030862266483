<template>
  <div class="authority">
    <a-tabs v-model="active" @change="tabsChange">
      <a-tab-pane key="1" tab=" 成绩调整"> </a-tab-pane>
      <a-tab-pane key="2" tab="整体数据查询"> </a-tab-pane>
      <a-tab-pane key="3" tab="单科数据查询"> </a-tab-pane>
      <a-tab-pane key="4" tab="客观题异常处理"> </a-tab-pane>
      <a-tab-pane key="5" tab="试题管理"> </a-tab-pane>
    </a-tabs>
    <div class="message">
      {{ messages[active] }}
    </div>
    <div class="operate">
      <div class="filter">
        <div class="filter-item">
          学段：
          <a-select
            v-model="search.level"
            placeholder="全部"
            style="width: 120px"
            not-found-content="暂无数据"
            allow-clear
            @change="filterChange"
          >
            <a-select-option value="1"> 小学 </a-select-option>
            <a-select-option value="2"> 初中 </a-select-option>
            <a-select-option value="3"> 高中 </a-select-option>
            <a-select-option value="4"> 大学 </a-select-option>
          </a-select>
        </div>
        <div v-if="['1', '2', '3'].includes(active)" class="filter-item">
          学年：
          <a-select
            v-model="search.year"
            placeholder="全部"
            not-found-content="暂无数据"
            style="width: 120px"
            allow-clear
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in yearList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
        <div v-if="active === '3'" class="filter-item">
          学科：
          <a-select
            v-model="search.subjectIds"
            placeholder="全部"
            not-found-content="暂无数据"
            style="width: 300px"
            mode="multiple"
            allow-clear
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in subjectList"
              :key="index"
              :value="item.subjectId"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="btns">
        <a-input
          v-model="search.name"
          style="width: 220px; margin-right: 8px"
          placeholder="请输入姓名或用户名"
          @input="filterChange"
        >
        </a-input>
        <a-button type="primary" icon="plus-circle" @click="add">新建</a-button>
        <a-button type="primary" @click="batch">
          <img
            src="@/assets/uExam/icon-del.png"
            style="width: 12px; margin-right: 6px"
            alt=""
          />批量删除</a-button
        >
        <a-button type="danger" @click="clearUser"
          ><img
            src="@/assets/uExam/clear-white.png"
            style="width: 12px; margin-right: 6px"
            alt=""
          />
          清空全部用户</a-button
        >
      </div>
    </div>
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="tableData"
      :locale="locale"
      :row-key="(record) => record.id"
      bordered
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      @change="tablePaginationChange"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)">修改</a-button>
        <a-button type="link" style="color: #f57574" @click="del(record)"
          >删除</a-button
        >

        <a-button type="link" style="color: #f57574" @click="clear(record)"
          >清空用户</a-button
        >
      </span>
    </a-table>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="title === '新建权限' || title === '修改权限' ? '48%' : '25%'"
      centered
      @cancel="handleCancel"
    >
      <template v-if="title === '新建权限' || title === '修改权限'">
        <div class="filter" style="margin-bottom: 18px">
          权限范围：
          <div class="filter-item">
            学段：
            <a-select
              v-model="authorityForm.level"
              not-found-content="暂无数据"
              style="width: 130px"
              allow-clear
              placeholder="请选择学段"
            >
              <a-select-option value="1"> 小学 </a-select-option>
              <a-select-option value="2"> 初中 </a-select-option>
              <a-select-option value="3"> 高中 </a-select-option>
              <a-select-option value="4"> 大学 </a-select-option>
            </a-select>
          </div>
          <div v-if="active === '3'" class="filter-item">
            学科：
            <a-select
              v-model="authorityForm.subjectIds"
              not-found-content="暂无数据"
              allow-clear
              mode="multiple"
              style="width: 120px"
              placeholder="请选择学科"
            >
              <a-select-option
                v-for="(item, index) in subjectList"
                :key="index"
                :value="item.subjectId"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </div>
          <div v-if="['1', '2', '3'].includes(active)" class="filter-item">
            学年：
            <a-select
              v-model="authorityForm.year"
              not-found-content="暂无数据"
              allow-clear
              mode="multiple"
              style="width: 120px"
              placeholder="请选择学年"
            >
              <a-select-option
                v-for="(item, index) in yearList"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <transfer
          :key="transferKey"
          :value="checkUserList"
          title="设置管理用户："
          :subject-select="active == 3"
          has-level
          @change="transferChange"
        />
      </template>
      <template v-if="title === '批量删除'">
        <div class="text">
          此操作将永久删除<span>所有权限及添加的用户名单！</span>
        </div>
        <div class="text">
          <el-input
            v-model="input"
            style="width: 0; height: 0; overflow: hidden"
            placeholder="请输入内容"
          ></el-input>
          验证登录密码以继续
          <a-input-password
            v-model="password"
            style="width: 180px; margin-left: 20px"
            autocomplete="new-password"
          ></a-input-password>
        </div>
        <div style="text-align: center">此操作不可撤销</div>
      </template>
      <template v-if="title === '清空用户'">
        <div class="text">
          此操作将永久删除<span>所有权限下已添加的用户名单！</span>
        </div>
        <el-input
          v-model="input"
          style="width: 0; height: 0; overflow: hidden"
          placeholder="请输入内容"
        ></el-input>
        <div class="text">
          验证登录密码以继续

          <a-input-password
            v-model="password"
            style="width: 180px; margin-left: 20px"
            autocomplete="new-password"
          ></a-input-password>
        </div>
        <div style="text-align: center">此操作不可撤销</div>
      </template>
      <template slot="footer">
        <template v-if="title === '新建权限' || title === '修改权限'">
          <a-button @click="handleCancel">取消</a-button>
          <a-button type="primary" :loading="confirmLoading" @click="submit"
            >确定</a-button
          >
        </template>
        <template v-if="title === '批量删除'">
          <div style="text-align: center">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              type="danger"
              :loading="confirmLoading"
              @click="batchSubmit"
              >批量删除</a-button
            >
          </div>
        </template>
        <template v-if="title === '清空用户'">
          <div style="text-align: center">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              type="danger"
              :loading="confirmLoading"
              @click="clearSubmit"
              >清空用户</a-button
            >
          </div>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import noData from "@/components/noData.vue";
import transfer from "../components/transfer.vue";
import { encryption } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import {
  permissionList,
  addPermission,
  editAuthority,
  delAuthority,
  cleanUser,
} from "@/core/api/newOther/union";
import { getStore } from "@/core/util/store";
export default {
  name: "Authority",
  components: {
    transfer,
  },
  data() {
    const schoolSubjects = getStore({ name: "schoolSubjectList" });
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      confirmLoading: false,
      active: "1",
      password: undefined,
      locale: {
        emptyText: <noData message="暂无数据" />,
      },
      messages: {
        1: "权限描述：管理指定学段、学年的考试成绩。",
        2: "权限描述：查询指定学段和学年所有已生成统计、低风险考试的全部数据和报告。",
        3: "权限描述：查询指定学段、学年和学科的已生成统计、低风险考试的全部考试和成绩数据以及报告。",
        4: "权限描述：查询指定学段进行中的考试扫描数据，可处理客观题识别异常数据。",
        5: "权限描述：使用对应学段的试题管理功能。",
      },
      search: {
        level: undefined,
        year: undefined,
        size: 20,
        current: 1,
      },
      input: "",
      yearList: [...yearList],
      total: 0,
      columns: [],

      authorityCol: [
        {
          title: "适用学段",
          dataIndex: "level",
          align: "center",
          width: 150,
          customRender: (text, record) => {
            let levelsObj = {
              1: "小学",
              2: "初中",
              3: "高中",
              4: "大学",
            };
            return levelsObj[record.level];
          },
        },
        {
          title: "适用学年",
          dataIndex: "year",
          width: 150,
          align: "center",
        },
        {
          title: "用户名单",
          dataIndex: "name",
          align: "center",
          customRender: (text, record) => {
            if (record.name && record.name.indexOf(",") != -1) {
              return (
                <div style="text-align:left">
                  {record.name.replace(/,/g, "，")}
                </div>
              );
            }
            return <div style="text-align:left">{record.name}</div>;
          },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 280,
        },
      ],
      tableData: [],
      selectedRows: [],
      title: "",
      visible: false,
      subjectList: [...schoolSubjects],
      authorityForm: {
        level: undefined,
        years: [],
        subjectIds: [],
      },
      checkUserList: [],
      transferKey: Math.random(),
      levelOptions: levelOptions(),
    };
  },
  computed: {
    rowSelection() {
      return {
        onSelect: (record, selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  created() {
    this.columns = [...this.authorityCol];
    this.getData();
  },
  methods: {
    transferChange(list) {
      this.checkUserList = list.map((item) => item.id);
    },
    async getData() {
      try {
        this.loading = true;
        const res = await permissionList({
          type: this.active,
          ...this.search,
          subjectIds: this.search.subjectIds
            ? this.search.subjectIds.toString()
            : "",
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    batchSubmit() {
      if (this.selectedRows.length) {
        this.$confirm("清空该条数据下已添加的用户名单，是否清空?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const user = encryption({
              data: {
                randomStr: "blockPuzzle",
                code: "xxx",
                password: this.password,
              },
              key: "pigxpigxpigxpigx",
              param: ["password"],
            });
            await delAuthority({
              ids: this.selectedRows.map((item) => item.id).join(","),
              ifBatch: true,
              password: user.password,
            });
            this.confirmLoading = false;
            this.search.current = 1;
            this.getData();
            this.visible = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要删除的权限",
          showClose: true,
        });
      }
    },
    clearSubmit() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码",
          type: "warning",
        });
        return false;
      }

      this.$confirm("清空该条权限下已添加用户名单，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.confirmLoading = true;
          try {
            const user = encryption({
              data: {
                randomStr: "blockPuzzle",
                code: "xxx",
                password: this.password,
              },
              key: "pigxpigxpigxpigx",
              param: ["password"],
            });
            await cleanUser({
              ifAll: true,
              password: user.password,
              type: this.active,
            });
            this.$message({
              showClose: true,
              message: "清除成功",
              type: "success",
            });
            this.confirmLoading = false;
            this.getData();
            this.visible = false;
          } catch {
            this.confirmLoading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    clearUser() {
      this.visible = true;
      this.title = "清空用户";
      this.password = null;
    },
    add() {
      this.title = "新建权限";
      this.visible = true;
      this.authorityForm = {
        level: undefined,
        year: [],
        subjectIds: [],
      };
      this.transferKey = Math.random();
    },
    batch() {
      if (this.selectedRows.length == 1) {
        this.$confirm("此操作将永久删除当前权限, 是否继续?", "操作提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            this.loading = true;
            try {
              await delAuthority({ ids: this.selectedRows[0].id });
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.loading = false;
              this.getData();
            } catch {
              this.loading = false;
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.visible = true;
        this.title = "批量删除";
      }
    },
    async submit() {
      if (!this.authorityForm.level) {
        this.$message({
          type: "warning",
          message: "请选择学段",
          showClose: true,
        });
        return;
      }
      if (["1", "2", "3"].includes(this.active)) {
        if (this.authorityForm.year.length === 0) {
          this.$message({
            type: "warning",
            message: "请选择学年",
            showClose: true,
          });
          return;
        }
      }
      if (this.active === "3") {
        if (this.authorityForm.subjectIds.length === 0) {
          this.$message({
            type: "warning",
            message: "请选择科目",
            showClose: true,
          });
          return;
        }
      }
      if (this.title === "新建权限") {
        try {
          this.confirmLoading = true;
          await addPermission({
            level: this.authorityForm.level,
            year: this.authorityForm.year.join(","),
            userIds: this.checkUserList.length
              ? this.checkUserList.join(",")
              : null,
            subjectIds: this.authorityForm.subjectIds.join(","),
            type: this.active,
          });
          this.confirmLoading = false;
          this.visible = false;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.checkUserList = [];
          this.getData();
        } catch {
          this.confirmLoading = false;
        }
      } else if (this.title === "修改权限") {
        this.confirmLoading = true;
        let params = {
          level: this.authorityForm.level,
          year: this.authorityForm.year.join(","),
          userIds: this.checkUserList.length
            ? this.checkUserList.join(",")
            : null,
          type: this.active,
          id: this.authorityForm.id,
        };
        if (this.active === "3") {
          params.subjectIds = this.authorityForm.subjectIds.join(",");
        }
        try {
          await editAuthority({ ...params });
          this.confirmLoading = false;
          this.visible = false;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.checkUserList = [];
          this.getData();
        } catch {
          this.confirmLoading = false;
        }
      }
    },
    handleCancel() {
      this.visible = false;
    },
    filterChange() {
      this.search.current = 1;
      this.getData();
    },
    tabsChange(val) {
      this.columns = [...this.authorityCol];
      if (val === "4" || val === "5") {
        delete this.columns[1];
      } else if (val === "3") {
        this.columns.splice(2, 0, {
          title: "学科",
          align: "center",
          dataIndex: "subjectIds",
          width: 100,
          customRender: (text, record) => {
            let subjectIds = record.subjectIds.split(",");
            let subjectLabels = subjectIds.map((item) => {
              let subject = this.subjectList.find((it) => it.value == item);
              return subject ? subject.label : "";
            });
            let arr = subjectLabels.filter((item) => item);
            return arr.join(",");
          },
        });
      }
      this.search.current = 1;
      this.getData();
    },
    edit(record) {
      this.authorityForm = {
        level: undefined,
        year: [],
      };
      this.title = "修改权限";
      this.visible = true;
      this.$nextTick(() => {
        this.authorityForm = { ...record };
        this.authorityForm.level = record.level.toString();
        this.authorityForm.subjectIds = this.authorityForm.year =
          record.year.split(",");
        this.checkUserList = record.userIds && record.userIds.split(",");
        if (this.active === "3") {
          if (record.subjectIds) {
            this.authorityForm.subjectIds = record.subjectIds
              .split(",")
              .map(Number);
          }
        }
        this.transferKey = Math.random();
      });
    },
    del(record) {
      this.$confirm("此操作将永久删除当前权限, 是否继续?", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.confirmLoading = true;
          try {
            await delAuthority({ ids: record.id });
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.confirmLoading = false;
            this.getData();
          } catch {
            this.confirmLoading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    clear(record) {
      this.$confirm("此操作将清空当前权限下的用户, 是否继续?", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.confirmLoading = true;
          try {
            await cleanUser({ ids: record.id });
            this.$message({
              showClose: true,
              message: "清除成功",
              type: "success",
            });
            this.confirmLoading = false;
            this.getData();
          } catch {
            this.confirmLoading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.authority {
  .message {
    height: 40px;
    background: #f3f8ff;
    border-radius: 4px;
    line-height: 40px;
    color: #262829;
    font-size: 14px;
    margin-bottom: 18px;
    box-sizing: border-box;
    padding: 0 15px;
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .btns {
      display: flex;
      align-items: center;
    }
  }
}
.ant-btn + .ant-btn {
  margin-left: 8px;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .filter-item {
    display: flex;
    align-items: center;
  }
  .filter-item + .filter-item {
    margin-left: 18px;
  }
}
.text {
  color: #0a1119;
  font-size: 14px;
  margin-bottom: 18px;
  span {
    color: #f57574;
  }
}
::v-deep .ant-modal-body {
  line-height: 1 !important;
}
</style>
